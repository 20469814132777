"use client";

import "swiper/css";
import "swiper/css/scrollbar";
import { A11y, FreeMode, Mousewheel, Scrollbar } from "swiper/modules";
import { A11Y_EXTERNAL_LINK_LABEL, cx } from "@tracksuit/utils";
import { resolveLink, sanityImage } from "$src/lib/sanity";
import { Swiper, SwiperSlide } from "swiper/react";
import { useCallback, useState, type ComponentProps } from "react";
import Link from "next/link";
import styles from "./image-list.module.css";
import swiperCommon from "$src/lib/swiper-common";

export type ImageListProps = {
  /** Optional Heading */
  heading?: string;
  region: string;
  /** Array of caseStudy objects: refactor schema */
  images: any[];
  /** Force all links to have noopener nofollow noreferrer. If combined with onClick, new tab is assumed */
  noopener?: boolean;
  /** */
  slidesOffsetBefore?: number[];
  /** Expose click handler */
  onClick?: (event: React.MouseEvent) => void;
} & ComponentProps<"div">;

/**
 * @component
 * Image list component for home page
 */
export function ImageList({
  heading,
  images,
  className,
  region,
  onClick,
  slidesOffsetBefore = [40, 64],
  noopener
}: ImageListProps) {
  const [show, setShow] = useState<boolean>(false);
  const init = useCallback(() => {
    setShow(true);
  }, []);

  const onClickHandler = useCallback(
    (e: React.MouseEvent) => {
      if (onClick) onClick(e);
    },
    [onClick]
  );

  return (
    <>
      {images && images.length > 0 ? (
        <div className={cx(className, styles.container)}>
          {heading ? <h2 className={styles.header}>{heading}</h2> : null}

          <Swiper
            {...swiperCommon}
            scrollbar={{
              el: ".imagelist-scrollbar",
              draggable: true,
              dragClass: "imagelist-swiper-drag",
              horizontalClass: "imagelist-swiper-scrollbar",
              enabled: true
            }}
            spaceBetween={20}
            slidesOffsetBefore={slidesOffsetBefore[0]}
            slidesOffsetAfter={40}
            slidesPerView={2}
            freeMode={true}
            onSwiper={init}
            autoHeight={true}
            modules={[FreeMode, Mousewheel, Scrollbar, A11y]}
            breakpoints={{
              900: {
                slidesPerView: 5,
                slidesOffsetBefore: slidesOffsetBefore[1],
                slidesOffsetAfter: 64
              },
              1440: {
                slidesPerView: 6,
                enabled: images.length > 6
              }
            }}
          >
            {images.map((image) => (
              <SwiperSlide key={image._key} className={styles.image}>
                <Link
                  href={resolveLink(region, image.link)}
                  className={cx(
                    styles["fade-in"],
                    show ? styles["show"] : styles["hide"]
                  )}
                  onClick={onClickHandler}
                  rel={noopener ? "noopener noreferrer nofollow" : undefined}
                >
                  <img
                    width={224}
                    loading="lazy"
                    alt={
                      onClick && noopener
                        ? A11Y_EXTERNAL_LINK_LABEL + image.title
                        : image.title
                    }
                    {...sanityImage(image.image)}
                  />
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className={styles["imagelist-scrollbar-container"]}>
            <div className="imagelist-scrollbar"></div>
          </div>
        </div>
      ) : null}
    </>
  );
}
